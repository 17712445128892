<template>
  <section>
    <b-card>
      <b-row>
        <b-col cols="2" v-for="store in stores" :key="store.id">
          <b-checkbox v-model="selectedStores" :value="store.id">{{ store.store_name }}</b-checkbox>
        </b-col>
        <b-col cols="2">
          <b-btn v-if="selectedStores.length<1" size="sm" @click="allSelect">Tümünü Seç</b-btn>
          <b-btn v-else size="sm" @click="allNotSelect">Tümünü Kaldır</b-btn>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="10">
          <!-- <b-textarea @keyup.enter="addAsinList" v-model="asinList" rows="5"></b-textarea> -->
          <b-textarea v-model="addAsins" rows="5"></b-textarea>
        </b-col>
        <b-col cols="2">
          <b-button :disabled="selectedStores.length<1 || addAsins.length<1" @click="addAsinList" variant="success">
            Ekle
          </b-button>
          <b-button variant="primary" @click="getAsinList">
            Getir
          </b-button>
        </b-col>
      </b-row>
    </b-card>


    <table class="table">
      <thead>
      <tr>
        <th scope="col">ASIN</th>
        <th scope="col" v-for="store in stores" :key="store.id">
          {{ store.store_name }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="azProduct in list" :key="azProduct.id">
        <th>{{ azProduct.merchant_sku }}</th>
        <td v-for="store in stores" :key="store.id">
          <div v-if="statusName(getData(store.id, azProduct.az_store_products)) !== null">
            <b-badge v-if="statusName(getData(store.id, azProduct.az_store_products))" variant="success">A</b-badge>
            <b-badge v-else variant="danger">P</b-badge>
          </div>
        </td>
      </tr>
      </tbody>
    </table>


  </section>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "@/libs/axios";

export default {
  data: () => ({
    addAsins: '',
    selectedStores: [],
    list: [],
  }),
  computed: {
    ...mapGetters('store', ['_stores']),
    stores() {
      return this._stores.filter(store => store.marketplace_code === 'AZ')
    }
  },
  methods: {
    allSelect() {
      this.selectedStores = this.stores.map(store => store.id)
    },
    allNotSelect() {
      this.selectedStores = [];
    },
    statusName(azStoreproduct) {
      if (azStoreproduct.id) {
        return !(azStoreproduct && [3, 4, 5].includes(azStoreproduct.status));
      }
      return null;

    },
    getData(storeId, azStoreProducts) {
      let data = azStoreProducts.find(azStoreProduct => azStoreProduct.store_id === storeId);
      if (data) {
        return data
      }
      return {}
    },
    getAsinList() {
      this.list = [];
      axios.get('/amazon/store/product/asinlist', {
        params: {
          asins: this.addAsins,
        }
      })
          .then(res => {
            this.list = res.data.content.data;
          })
    },
    addAsinList() {
      axios.post('/amazon/store/product/addAsinList', {
        selectedStores: this.selectedStores,
        list: this.addAsins,
      })
          .then(res => {
            this.$swal({
              title: res.data.message,
              icon: res.data.status,
            })
            if (res.data.status === 'success') {
              this.list = res.data.content;
            }
          })
    }
  },
  created() {
    this.$store.dispatch('store/stores')
    //this.getAsinList()
  }

}
</script>